///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Posts */

	ul.posts {
		list-style: none;
		padding: 0;

		li {
			border-top: dotted 1px _palette(border);
			margin: (_size(element-margin) * 0.75) 0 0 0;
			padding: (_size(element-margin) * 0.75) 0 0 0;

			&:first-child {
				border-top: 0;
				margin-top: 0;
				padding-top: 0;
			}
		}

		article {
			@include vendor('display', 'flex');
			@include vendor('align-items', 'flex-start');
			@include vendor('flex-direction', 'row-reverse');

			.image {
				display: block;
				margin-right: 1.5em;
				min-width: 4em;
				width: 4em;

				img {
					width: 100%;
				}
			}

			header {
				@include vendor('flex-grow', '1');
				-ms-flex: 1;

				h3 {
					font-size: 0.7em;
					margin-top: 0.125em;
				}

				.published {
					display: block;
					font-family: _font(family-heading);
					font-size: 0.6em;
					font-weight: _font(weight-heading);
					letter-spacing: _font(kerning-heading);
					margin: -0.625em 0 (_size(element-margin) * 0.85) 0;
					text-transform: uppercase;
				}

				> :last-child {
					margin-bottom: 0;
				}
			}
		}
	}