///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Intro */

	#intro {
		.logo {
			border-bottom: 0;
			display: inline-block;
			margin: 0 0 (_size(element-margin) * 0.5) 0;
			overflow: hidden;
			position: relative;
			// width: 4em;

			// &:before {
			// 	background-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="none" zoomAndPan="disable"><polygon points="0,0 100,0 100,25 50,0 0,25" style="fill:#{_palette(bg-alt)}" /><polygon points="0,100 100,100 100,75 50,100 0,75"  style="fill:#{_palette(bg-alt)}" /></svg>');
			// 	background-position: top left;
			// 	background-repeat: no-repeat;
			// 	background-size: 100% 100%;
			// 	content: '';
			// 	display: block;
			// 	height: 100%;
			// 	left: 0;
			// 	position: absolute;
			// 	top: 0;
			// 	width: 100%;
			// }

			img {
				display: block;
				border-radius: 50%;
				width: 10em;
			}
		}

		header {
			h2 {
				font-size: 2em;
				font-weight: _font(weight-heading-extrabold);
			}

			p {
				font-size: 0.8em;
			}
		}

		@include breakpoint('<=large') {
			margin: 0 0 _size(section-spacing) 0;
			text-align: center;

			header {
				h2 {
					font-size: 2em;
				}

				p {
					font-size: 0.7em;
				}
			}
		}

		@include breakpoint('<=small') {
			margin: 0 0 _size(section-spacing-small) 0;
			padding: 1.25em 0;

			> :last-child {
				margin-bottom: 0;
			}

			.logo {
				margin: 0 0 (_size(element-margin) * 0.25) 0;
			}

			header {
				h2 {
					font-size: 1.25em;
				}

				> :last-child {
					margin-bottom: 0;
				}
			}
		}
	}