///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Menu */

	#menu {
		@include vendor('transform', 'translateX(#{_size(menu)})');
		@include vendor('transition', (
			'transform #{_duration(menu)} ease',
			'visibility #{_duration(menu)}'
		));
		-webkit-overflow-scrolling: touch;
		background: _palette(bg);
		border-left: solid 1px _palette(border);
		box-shadow: none;
		height: 100%;
		max-width: 80%;
		overflow-y: auto;
		position: fixed;
		right: 0;
		top: 0;
		visibility: hidden;
		width: _size(menu);
		z-index: _misc(z-index-base) + 2;

		> * {
			border-top: solid 1px _palette(border);
			padding: _size(section-spacing);

			> :last-child {
				margin-bottom: 0;
			}
		}

		> :first-child {
			border-top: 0;
		}

		.links {
			list-style: none;
			padding: 0;

			> li {
				border: 0;
				border-top: dotted 1px _palette(border);
				margin: 1.5em 0 0 0;
				padding: 1.5em 0 0 0;

				a {
					display: block;
					border-bottom: 0;

					h3 {
						@include vendor('transition', 'color #{_duration(transition)} ease');
						font-size: 0.7em;
					}

					p {
						font-family: _font(family-heading);
						font-size: 0.6em;
						font-weight: _font(weight-heading);
						letter-spacing: _font(kerning-heading);
						letter-spacing: _size(letter-spacing-alt);
						margin-bottom: 0;
						text-decoration: none;
						text-transform: uppercase;
					}

					&:hover {
						h3 {
							color: _palette(accent);
						}
					}
				}

				&:first-child {
					border-top: 0;
					margin-top: 0;
					padding-top: 0;
				}
			}
		}

		body.is-menu-visible & {
			@include vendor('transform', 'translateX(0)');
			visibility: visible;
		}

		@include breakpoint('<=small') {
			> * {
				padding: _size(section-spacing-small);
			}
		}
	}