///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Sidebar */

	#sidebar {
		margin-right: _size(section-spacing);
		min-width: 22em;
		width: 22em;

		> * {
			border-top: solid 1px _palette(border);
			margin: _size(section-spacing) 0 0 0;
			padding: _size(section-spacing) 0 0 0;
		}

		> :first-child {
			border-top: 0;
			margin-top: 0;
			padding-top: 0;
		}

		@include breakpoint('<=large') {
			border-top: solid 1px _palette(border);
			margin: _size(section-spacing) 0 0 0;
			min-width: 0;
			padding: _size(section-spacing) 0 0 0;
			width: 100%;
			overflow-x: hidden;
		}
	}