///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Header */

	body {
		padding-top: 3.5em;
	}

	#header {
		@include vendor('display', 'flex');
		@include vendor('justify-content', 'space-between');
		background-color: _palette(bg);
		border-bottom: solid 1px _palette(border);
		height: 3.5em;
		left: 0;
		line-height: 3.5em;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: _misc(z-index-base);

		a {
			color: inherit;
			text-decoration: none;
		}

		ul {
			list-style: none;
			margin: 0;
			padding-left: 0;

			li {
				display: inline-block;
				padding-left: 0;
			}
		}

		h1 {
			height: inherit;
			line-height: inherit;
			padding: 0 0 0 1.5em;
			white-space: nowrap;

			a {
				font-size: 0.7em;
			}
		}

		.links {
			@include vendor('flex', '1');
			border-left: solid 1px _palette(border);
			height: inherit;
			line-height: inherit;
			margin-left: 1.5em;
			overflow: hidden;
			padding-left: 1.5em;

			ul {
				li {
					border-left: solid 1px _palette(border);
					line-height: 1;
					margin-left: 1em;
					padding-left: 1em;

					&:first-child {
						border-left: 0;
						margin-left: 0;
						padding-left: 0;
					}

					a {
						border-bottom: 0;
						font-family: _font(family-heading);
						font-size: 0.7em;
						font-weight: _font(weight-heading);
						letter-spacing: _font(kerning-heading);
						text-transform: uppercase;
					}
				}
			}
		}

		.main {
			height: inherit;
			line-height: inherit;
			text-align: right;

			ul {
				height: inherit;
				line-height: inherit;

				li {
					border-left: solid 1px _palette(border);
					height: inherit;
					line-height: inherit;
					white-space: nowrap;

					> * {
						display: block;
						float: left;
					}

					> a {
						@include icon(false, solid);
						border-bottom: 0;
						color: _palette(fg-light);
						overflow: hidden;
						position: relative;
						text-indent: 4em;
						width: 4em;

						&:before {
							display: block;
							height: inherit;
							left: 0;
							line-height: inherit;
							position: absolute;
							text-align: center;
							text-indent: 0;
							top: 0;
							width: inherit;
						}
					}
				}
			}
		}

		form {
			margin: 0;

			input {
				display: inline-block;
				height: 2.5em;
				position: relative;
				top: -2px;
				vertical-align: middle;
			}
		}

		#search {
			@include vendor('transition', 'all 0.5s ease');
			max-width: 0;
			opacity: 0;
			overflow: hidden;
			padding: 0;
			white-space: nowrap;

			input {
				width: 12em;
			}

			&.visible {
				max-width: 12.5em;
				opacity: 1;
				padding: 0 0.5em 0 0;
			}
		}

		@include breakpoint('<=medium') {
			.links {
				display: none;
			}
		}

		@include breakpoint('<=small') {
			height: 2.75em;
			line-height: 2.75em;

			h1 {
				padding: 0 0 0 1em;
			}

			.main {
				.search {
					display: none;
				}
			}
		}
	}